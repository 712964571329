export const Columns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    align: 'left',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '流程主题',
    dataIndex: 'fileName',
    key: 'fileName',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'fileName' },
    width: 200
  },
  {
    title: '申请人',
    dataIndex: 'staffName',
    key: 'staffName',
    ellipsis: true,
    align: 'left',
    width: 200
  },
  {
    title: '文件编号',
    dataIndex: 'contractNo',
    key: 'contractNo',
    ellipsis: true,
    align: 'left',
    width: 200
  },
  {
    title: '印章名称',
    dataIndex: 'sealName',
    key: 'sealName',
    align: 'left',
    slots: { customRender: 'sealName' },
    ellipsis: true,
    width: 150
  },
  {
    title: '接收方',
    dataIndex: 'signatory',
    key: 'signatory',
    align: 'left',
    slots: { customRender: 'signatory' },
    ellipsis: true,
    width: 100
  },
  {
    title: '签署状态',
    dataIndex: 'status',
    key: 'status',
    align: 'left',
    slots: { customRender: 'status' },
    width: 150
  },
  {
    title: '申请时间',
    dataIndex: 'originateTime',
    key: 'originateTime',
    align: 'left',
    slots: { customRender: 'originateTime' },
    width: 170
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 150
  }
]

export const baseColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    align: 'left',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '流程主题',
    dataIndex: 'fileName',
    key: 'fileName',
    align: 'left',
    ellipsis: true,
    width: 100
  },
  {
    title: '申请人',
    dataIndex: 'staffName',
    key: 'staffName',
    align: 'left',
    ellipsis: true,
    width: 100
  },
  {
    title: '申请印章',
    dataIndex: 'sealNames',
    key: 'sealNames',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'sealNames' },
    width: 100
  },
  {
    title: '申请次数',
    dataIndex: 'applySums',
    key: 'applySums',
    align: 'left',
    width: 100
  },
  {
    title: '实际盖印次数',
    dataIndex: 'practicalNumber',
    key: 'practicalNumber',
    align: 'left',
    slots: { customRender: 'practicalNumber' },
    width: 100
  },
  {
    title: '盖印时间',
    dataIndex: 'applyTime',
    key: 'applyTime',
    align: 'left',
    ellipsis: true,
    sorter: true,
    slots: { customRender: 'applyTime' },
    width: 150
  },
  // {
  //   title: '用印状态',
  //   dataIndex: 'sealStatus',
  //   key: 'sealStatus',
  //   align: 'left',
  //   slots: { customRender: 'sealStatus' },
  //   width: 100
  // },
  // {
  //   title: '审批状态',
  //   dataIndex: 'examineStatus',
  //   key: 'examineStatus',
  //   align: 'left',
  //   slots: { customRender: 'examineStatus' },
  //   width: 300
  // },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'right',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 300
  }
]
